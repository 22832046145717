import React from "react"
import ReactMarkdown from "react-markdown";
import SEO from "../components/common/seo"
import LayoutSecondary from "../components/common/layout-secondary";
import {graphql} from 'gatsby'
import {customElements, sortAsc} from "../utils/global-functions";

import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import rehypeRaw from "rehype-raw";

const AboutPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const about = data.allStrapiSobreTecalis.edges[0].node;
    const historyItems = about.history_items.sort(sortAsc);
    const videoCode = lang === 'es' ? 'cG4MSgMTCkE' : '5D4Uoio5qqc';

    return (
        <LayoutSecondary pageContext={pageContext} cookies={data.cookies}>
            <SEO lang={lang} title={about.seo.title} description={about.seo.meta_description}/>

            {/** SOBRE TECALIS
             ================================================== */}
            <section className="position-relative py-8 py-md-11 mb-6 bg-white">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-xl-5 mb-5" data-aos="fade-up">
                            <h1 className="display-4 font-weight-bold spacing title-feed">
                                {about.title}
                                <br/><br/>
                            </h1>

                            <ReactMarkdown children={about.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing', '', '', '', 'font-size-sm text-black-80 mb-0 spacing')}/>

                            {/*<p className="font-size-sm text-black-80 mb-0 spacing">
                                * Sociedad: Tecalis Software S.L. (antes, Dexga Technologies S.L.) Puesto 145 en 2018. Puesto 811 en 2019.
                            </p>*/}

                            <br/>

                            <img src={require("../img/ft2018.png").default} width="200" alt="..." className="img-fluid rounded ft mt-2 mr-2 mb-2" data-aos="fade-right"/>
                            <img src={require("../img/ft2019.png").default} width="200" alt="..." className="img-fluid ft rounded" data-aos="fade-left"/>
                        </div>

                        <div className="col-xl-1"/>

                        <div className="col-xl-6">
                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8"
                                 style={{position: "absolute", top: "250px", right: "-30px", zIndex: "-99"}}>
                                <svg width="185" height="186" viewBox="0 0 185 186" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="62" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="82" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="102" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="122" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="142" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="162" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="182" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="182" r="2" fill="#f43c51"/>
                                </svg>
                            </div>

                            {/** Vídeo */}
                            <a data-small-btn="true" href={`https://youtu.be/${videoCode}`} className="desktop--version" target="_blank" rel="noopener noreferrer" data-fancybox="">
                                <img src={require("../images/sobre-tecalis_01.png").default} alt="..." className="img-fluid" data-aos="fade-left"/>
                            </a>
                            {/** Vídeo */}
                            <a href={`https://youtu.be/${videoCode}`} className="mobile--version" target="_blank" rel="noopener noreferrer">
                                <img src={require("../images/sobre-tecalis_01.png").default} alt="..." className="img-fluid" data-aos="fade-left"/>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/** A LA VANGUARDIA DE LA TRANSFORMACIÓN DIGITAL
             ======================================================== */}
            <section className="position-relative py-8 py-md-11 mb-9 border-top bg-white">

                {/** Content */}
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-lg-6">

                            {/** Decoration */}
                            <div className="position-absolute top-md-left text-warning mt-n9 m-md-n9"
                                 style={{position: "absolute", zIndex: "-9", right: 0, bottom: 0}}>
                                <svg width="278" height="278" viewBox="0 0 278 278" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#f43c51" d="M3 0h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 3h8v2H0zM33 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 3h8v2h-8zM63 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 3h8v2h-8zM93 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 3h8v2h-8zM123 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 3h8v2h-8zM153 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 3h8v2h-8zM183 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 3h8v2h-8zM213 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 3h8v2h-8zM243 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 3h8v2h-8zM273 0h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 3h8v2h-8zM3 30h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 33h8v2H0zM33 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 33h8v2h-8zM63 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 33h8v2h-8zM93 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 33h8v2h-8zM123 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 33h8v2h-8zM153 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 33h8v2h-8zM183 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 33h8v2h-8zM213 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 33h8v2h-8zM243 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 33h8v2h-8zM273 30h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 33h8v2h-8zM3 60h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 63h8v2H0zM33 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 63h8v2h-8zM63 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 63h8v2h-8zM93 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 63h8v2h-8zM123 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 63h8v2h-8zM153 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 63h8v2h-8zM183 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 63h8v2h-8zM213 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 63h8v2h-8zM243 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 63h8v2h-8zM273 60h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 63h8v2h-8zM3 90h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 93h8v2H0zM33 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 93h8v2h-8zM63 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 93h8v2h-8zM93 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 93h8v2h-8zM123 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 93h8v2h-8zM153 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 93h8v2h-8zM183 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 93h8v2h-8zM213 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 93h8v2h-8zM243 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 93h8v2h-8zM273 90h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 93h8v2h-8zM3 120h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 123h8v2H0zM33 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 123h8v2h-8zM63 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 123h8v2h-8zM93 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 123h8v2h-8zM123 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 123h8v2h-8zM153 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 123h8v2h-8zM183 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 123h8v2h-8zM213 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 123h8v2h-8zM243 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 123h8v2h-8zM273 120h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 123h8v2h-8zM3 150h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 153h8v2H0zM33 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 153h8v2h-8zM63 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 153h8v2h-8zM93 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 153h8v2h-8zM123 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 153h8v2h-8zM153 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 153h8v2h-8zM183 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 153h8v2h-8zM213 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 153h8v2h-8zM243 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 153h8v2h-8zM273 150h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 153h8v2h-8zM3 180h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 183h8v2H0zM33 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 183h8v2h-8zM63 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 183h8v2h-8zM93 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 183h8v2h-8zM123 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 183h8v2h-8zM153 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 183h8v2h-8zM183 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 183h8v2h-8zM213 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 183h8v2h-8zM243 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 183h8v2h-8zM273 180h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 183h8v2h-8zM3 210h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 213h8v2H0zM33 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 213h8v2h-8zM63 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 213h8v2h-8zM93 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 213h8v2h-8zM123 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 213h8v2h-8zM153 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 213h8v2h-8zM183 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 213h8v2h-8zM213 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 213h8v2h-8zM243 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 213h8v2h-8zM273 210h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 213h8v2h-8zM3 240h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 243h8v2H0zM33 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 243h8v2h-8zM63 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 243h8v2h-8zM93 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 243h8v2h-8zM123 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 243h8v2h-8zM153 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 243h8v2h-8zM183 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 243h8v2h-8zM213 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 243h8v2h-8zM243 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 243h8v2h-8zM273 240h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 243h8v2h-8zM3 270h2v8H3z"/>
                                    <path fill="#f43c51" d="M0 273h8v2H0zM33 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M30 273h8v2h-8zM63 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M60 273h8v2h-8zM93 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M90 273h8v2h-8zM123 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M120 273h8v2h-8zM153 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M150 273h8v2h-8zM183 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M180 273h8v2h-8zM213 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M210 273h8v2h-8zM243 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M240 273h8v2h-8zM273 270h2v8h-2z"/>
                                    <path fill="#f43c51" d="M270 273h8v2h-8z"/>
                                </svg>
                            </div>

                            {/** Image */}
                            <img src={require("../images/sobre-tecalis_02.png").default} alt="..."
                                 className="img-fluid rounded mw-130 float-right mb-6 mb-md-0" data-aos="fade-right"/>
                            {/**- Deco */}
                            {/**img src="./assets/img/marco4.svg" className="img-fluid rombo" data-aos="fade-right"*/}
                        </div>

                        <div className="col-12 col-md-6 col-lg-5 offset-lg-1" data-aos="fade-up">
                            <h2 className="display-4 font-weight-bold spacing">
                                {about.partner_title}
                                <br/><br/>
                            </h2>

                            <ReactMarkdown children={about.partner_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')}/>
                        </div>
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}
            </section>

            {/** PIONEROS
             ================================================== */}
            <section className="position-relative py-8 py-md-11 mb-9 bg-move-static">

                {/** Content */}
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-12 col-md-6 col-lg-5" data-aos="fade-up">

                            {/** Heading */}
                            <h2 className="display-4 font-weight-bold spacing text-white">
                                {about.pioneers_title}
                                <br/><br/>
                            </h2>

                            <ReactMarkdown children={about.pioneers_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-white mb-6 spacing')}/>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 offset-lg-1">

                            {/** Decoration */}
                            <div className="position-absolute top-right text-primary-dark mt-n12"
                                 style={{
                                     position: "absolute",
                                     zIndex: "-99",
                                     left: "-30px",
                                     top: "520px",
                                     transform: "rotate(180deg)"
                                 }}>
                                <svg width="129" height="208" viewBox="0 0 129 208" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#decoration5clip0)">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M142.812 201.281a6.47 6.47 0 1112.94.002 6.47 6.47 0 01-12.94-.002zm1.618 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.811-1.143-1.144 4.811-4.811-4.811-4.811 1.143-1.143 4.811 4.811zm-32.343 1.143a6.468 6.468 0 1112.936 0 6.468 6.468 0 01-12.936 0zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.811-1.143-1.144 4.811-4.811-4.811-4.811 1.143-1.143 4.811 4.811z"
                                              fill="#fff"/>
                                    </g>
                                    <g clipPath="url(#decoration5clip1)">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M142.812 149.281a6.47 6.47 0 1112.94.002 6.47 6.47 0 01-12.94-.002zm1.618 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.811-1.143-1.144 4.811-4.811-4.811-4.811 1.143-1.143 4.811 4.811zm-32.343 1.143a6.468 6.468 0 1112.936 0 6.468 6.468 0 01-12.936 0zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.811-1.143-1.144 4.811-4.811-4.811-4.811 1.143-1.143 4.811 4.811z"
                                              fill="#fff"/>
                                    </g>
                                    <g clipPath="url(#decoration5clip2)">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M142.812 97.281a6.47 6.47 0 1112.939.002 6.47 6.47 0 01-12.939-.002zm1.618 0a4.851 4.851 0 119.703 0 4.851 4.851 0 01-9.703 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.81-1.143-1.143 4.811-4.81-4.811-4.812 1.143-1.143 4.811 4.81zm-32.343 1.143a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.937 0zm1.617 0a4.851 4.851 0 119.703 0 4.851 4.851 0 01-9.703 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.811-4.811-4.811 4.811-1.143-1.144 4.811-4.81-4.811-4.812 1.143-1.143 4.811 4.81z"
                                              fill="#fff"/>
                                    </g>
                                    <g clipPath="url(#decoration5clip3)">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M38.813 149.281a6.47 6.47 0 1112.938 0 6.47 6.47 0 01-12.938 0zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.811-4.81-4.811 1.143-1.143 4.811 4.811zm-32.343 1.143a6.469 6.469 0 1112.937.001 6.469 6.469 0 01-12.938-.001zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.811-4.81-4.811 1.143-1.143 4.811 4.811z"
                                              fill="#fff"/>
                                    </g>
                                    <g clipPath="url(#decoration5clip4)">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M38.813 97.281a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.938 0zm1.617 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.81-1.143-1.143 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81zm-32.343 1.143a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.938 0zm1.617 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81z"
                                              fill="#fff"/>
                                    </g>
                                    <g clipPath="url(#decoration5clip5)">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M38.813 45.281a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.938 0zm1.617 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.81-1.143-1.143 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81zm-32.343 1.143a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.938 0zm1.617 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.81-1.143-1.143 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81z"
                                              fill="#fff"/>
                                    </g>
                                    <g clipPath="url(#decoration5clip6)">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M90.813 175.281a6.47 6.47 0 1112.938 0 6.47 6.47 0 01-12.939 0zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.811-4.81-4.811 1.143-1.143 4.811 4.811zm-32.344 1.143a6.469 6.469 0 1112.938.001 6.469 6.469 0 01-12.938-.001zm1.618 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.811-4.81-4.811 1.143-1.143 4.811 4.811z"
                                              fill="#fff"/>
                                    </g>
                                    <g clipPath="url(#decoration5clip7)">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M90.813 123.281a6.47 6.47 0 1112.938 0 6.47 6.47 0 01-12.939 0zm1.617 0a4.851 4.851 0 119.702 0 4.851 4.851 0 01-9.702 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81zm-32.344 1.143a6.469 6.469 0 1112.938 0 6.469 6.469 0 01-12.938 0zm1.618 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.811-1.143-1.144 4.81-4.811-4.81-4.811 1.143-1.143 4.811 4.811z"
                                              fill="#fff"/>
                                    </g>
                                    <g clipPath="url(#decoration5clip8)">
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M90.813 71.281a6.469 6.469 0 1112.937 0 6.469 6.469 0 01-12.938 0zm1.617 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851-27.018l4.811-4.811 1.144 1.143-4.811 4.811 4.811 4.811-1.144 1.144-4.81-4.811-4.812 4.81-1.143-1.143 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81zm-32.344 1.143a6.469 6.469 0 1112.938 0 6.469 6.469 0 01-12.938 0zm1.618 0a4.852 4.852 0 119.703 0 4.852 4.852 0 01-9.703 0zm4.851 24.732l4.811-4.811 1.144 1.143-4.811 4.811 4.81 4.811-1.143 1.144-4.81-4.811-4.812 4.81-1.143-1.143 4.81-4.81-4.81-4.812 1.143-1.143 4.811 4.81z"
                                              fill="#fff"/>
                                    </g>
                                    <defs>
                                        <clipPath id="decoration4clip0">
                                            <path transform="matrix(0 -1 -1 0 155.75 207.75)" fill="#fff"
                                                  d="M0 0h51.75v51.75H0z"/>
                                        </clipPath>
                                        <clipPath id="decoration4clip1">
                                            <path transform="rotate(-90 155.75 0)" fill="#fff" d="M0 0h51.75v51.75H0z"/>
                                        </clipPath>
                                        <clipPath id="decoration4clip2">
                                            <path transform="matrix(0 -1 -1 0 155.75 103.75)" fill="#fff"
                                                  d="M0 0h51.75v51.75H0z"/>
                                        </clipPath>
                                        <clipPath id="decoration4clip3">
                                            <path transform="matrix(0 -1 -1 0 51.75 155.75)" fill="#fff"
                                                  d="M0 0h51.75v51.75H0z"/>
                                        </clipPath>
                                        <clipPath id="decoration4clip4">
                                            <path transform="matrix(0 -1 -1 0 51.75 103.75)" fill="#fff"
                                                  d="M0 0h51.75v51.75H0z"/>
                                        </clipPath>
                                        <clipPath id="decoration4clip5">
                                            <path transform="matrix(0 -1 -1 0 51.75 51.75)" fill="#fff"
                                                  d="M0 0h51.75v51.75H0z"/>
                                        </clipPath>
                                        <clipPath id="decoration4clip6">
                                            <path transform="matrix(0 -1 -1 0 103.75 181.75)" fill="#fff"
                                                  d="M0 0h51.75v51.75H0z"/>
                                        </clipPath>
                                        <clipPath id="decoration4clip7">
                                            <path transform="matrix(0 -1 -1 0 103.75 129.75)" fill="#fff"
                                                  d="M0 0h51.75v51.75H0z"/>
                                        </clipPath>
                                        <clipPath id="decoration4clip8">
                                            <path transform="matrix(0 -1 -1 0 103.75 77.75)" fill="#fff"
                                                  d="M0 0h51.75v51.75H0z"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            {/** Img */}
                            <img src={require("../images/sobre-tecalis_03.png").default} alt="..."
                                 className="img-fluid rounded mw-130 float-left mb-6 mb-md-0" data-aos="fade-left"/>
                            {/**- Deco */}
                            {/**img src="./assets/img/marco3.svg" className="img-fluid marco" data-aos="fade-left"*/}

                        </div>

                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}

            </section>


            {/** CRECIMIENTO SOSTENIDO Y SOSTENIBLE
             ======================================================== */}
            <section className="position-relative py-8 py-md-11 mb-9 bg-white">

                {/** Content */}
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-5">
                            {/** Image */}
                            <img src={require("../images/sobre-tecalis_04.png").default} alt="..."
                                 className="img-fluid rounded mb-6 mb-md-0"
                                 data-aos="fade-right"/>
                        </div>
                        <div className="col-xl-1"/>
                        <div className="col-xl-6" data-aos="fade-up">

                            {/** Heading */}
                            <h2 className="display-4 font-weight-bold spacing">
                                {about.increase_title}
                                <br/><br/>
                            </h2>

                            <ReactMarkdown children={about.increase_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')}/>
                        </div>
                    </div>
                </div>
                {/** / .row */}
            </section>
            {/** / .container */}

            {/** HITOS PRINCIPALES
             ================================================== */}
            <section className="pt-8 pb-9 pt-md-11 pb-md-13 border-top">

                <div className="container">

                    {/** Heading */}
                    <h2 align="center" className="display-4 font-weight-bold spacing">
                        {about.history_title}
                        <br/><br/>
                    </h2>

                    <div id="cd-timeline" className="cd-container">
                        {historyItems.map((item, i) => {
                            return <div className="cd-timeline-block" key={i}>
                                <div className="cd-timeline-img cd-tecalis"/>

                                <div className="cd-timeline-content" data-aos={i % 2 === 0 ? 'fade-right' : 'fade-left'}>
                                    <h2 className="spacing font-weight-bold"><i className="fe fe-calendar"/> {item.title}</h2>

                                    <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('spacing')}/>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </section>
        </LayoutSecondary>
    )
}

export default AboutPage

export const aboutPageQuery = graphql
    `query($lang: String) {
    allStrapiSobreTecalis (filter: { locale: { eq: $lang } }) {
        edges {
            node {
                seo {
                    title
                    meta_description
                }
                title
                description
                partner_title
                partner_description
                pioneers_title
                pioneers_description
                increase_title
                increase_description
                history_title
                history_items {
                    order
                    title
                    description
                }
            }
        }
    }
    cookies: markdownRemark(
        frontmatter: {
            lang: { eq: $lang }
            name: { eq: "cookies" }
        }
    ) {
        frontmatter {
            cookiesStart
            cookiesEnd
            linkText
        }
    }
}`;
